import React from "react";
import styled from "styled-components";
import { Helmet } from "react-helmet";
import GlobalStyles from "../styles/GlobalStyles";
import Footer from "./Footer";
import Header from "./Header";
// import Navigation from './Navigation';
import TopMenuBasicPlaceholder from "./TopMenuBasicPlaceholder";

const SiteBorderStyles = styled.div`
  max-width: 1100px;
  margin: 4rem auto 4rem auto;
  /* margin-top: clamp(2rem, 10vw, 12rem); */
  background-size: 1500px;
  background-color: var(--background-color);
  padding: clamp(5px, 1vw, 25px);
  box-shadow: 0 0 5px 3px rgba(0, 0, 0, 0.044);
  border: 5px solid var(--background-color);
  transition: all var(transition-timeframe);
  @media (max-width: 1100px) {
    margin-left: 1.5rem;
    margin-right: 1.5rem;
  }
`;

export default function Layout({ children }) {
  return (
    <>
      <GlobalStyles />
      <Helmet>
        <link rel="manifest" href="/site.webmanifest" />
        <script
          src="https://www.gstatic.com/dialogflow-console/fast/messenger/bootstrap.js?v=1"
          type="text/javascript"
        />
      </Helmet>
      <df-messenger
        intent="WELCOME"
        chat-title="Chat with Nick's Bot"
        agent-id="01cd0c26-b28a-4eb6-bd71-00b9a5608305"
        language-code="en"
        chat-icon="/favicon-32x32.png"
      />

      {children.props.location.pathname === "/" ? (
        <>
          <TopMenuBasicPlaceholder />
          {children}
        </>
      ) : (
        <>
          <Header />
          <TopMenuBasicPlaceholder />
          <SiteBorderStyles>{children}</SiteBorderStyles>
        </>
      )}
      {/* <Navigation /> */}
      <Footer />
    </>
  );
}
