import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import headerBg from "../assets/images/headerBackground.svg";
import { StaticImage } from "gatsby-plugin-image";
// import backgroundImage from '../assets/images/designerBackground.svg';
import placeholderText from "../assets/images/websitePlaceholderText.png";

const GeneralHeader = styled.header`
  display: grid;
  grid-template-columns: auto auto auto;
  background-image: url(${headerBg});
  background-size: 3000px;
  background-attachment: fixed;
  grid-template-columns: auto auto auto;
  color: white;
  * {
    margin: auto;
  }
  .headerLogo {
    width: 4em;
    max-width: 100%;
    margin: 0 auto;
    object-fit: contain;
    margin: 10px;
  }
  .headerText {
    text-align: center !important;
  }
  @media (max-width: 760px) {
    grid-template-columns: auto auto auto;
    height: 50px;
    .headerLogo {
      width: 30px;
    }
    .headerText {
      height: 40px;
    }
  }
`;

export default function Header() {
  return (
    <GeneralHeader>
      <Link to="/" title="Homepage">
        <StaticImage
          className="headerLogo"
          src="../assets/images/headerLogo.png"
          alt="Nicholas Shaw homepage"
          placeholder="blurred"
        />
      </Link>
      <StaticImage
        src="../assets/images/websitePlaceholderText.png"
        alt="Nicholas Shaw homepage"
        placeholder="blurred"
      />
    </GeneralHeader>
  );
}
