import React, { useState, useEffect } from "react";
import styled from "styled-components";
import logoBannerLight from "../assets/images/logoshawbanner_light.png";
import logoBannerDark from "../assets/images/logoshawbanner_dark.png";
import UseSound from "use-sound";
import swtichSound from "../assets/sound/switch.mp3";

const switchHeight = 17;
const switchPadding = 2;

const OuterDarkmodeSwitch = styled.div`
  position: absolute;
  top: 0px;
  right: 0px;
  z-index: 100;
  margin: 0.5em;
  color: white;
  padding: 3px;
`;

const DarkmodeLabel = styled.label`
  position: relative;
  cursor: pointer;
  display: inline-block;
  width: ${switchHeight * 2 + switchPadding * 4}px;
  height: ${switchHeight + switchPadding * 2}px;
  background-color: white;
  border-radius: ${switchHeight}px;
  input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  input:checked + span {
    background-color: yellow;
  }
  input:checked + span {
    box-shadow: 0 0 10px yellow;
  }
  input:focus + span {
    border: 1px solid var(--brand-orange);
  }
  input:checked + span:before {
    transform: translateX(${switchHeight + switchPadding}px);
  }
  input:checked + p {
    display: none;
  }
  p {
    display: block;
    color: var(--brand-blue-700);
    position: relative;
    text-align: right;
    font-size: ${switchHeight}px;
    margin: 0;
    padding: ${switchPadding}px;
    line-height: ${switchHeight}px;
    font-size: ${switchHeight}px;
    top: -${switchHeight + 3}px;
    right: ${switchPadding * 1.5 - switchPadding}px;
    z-index: 1;
    user-select: none;
  }
  span {
    position: absolute;
    background-color: white;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border: 1px solid #ccc;
    border-radius: ${switchHeight}px;
    transition: 0.2s;
    &:before {
      position: absolute;
      content: "";
      height: ${switchHeight}px;
      width: ${switchHeight}px;
      background-color: white;
      border: 1px solid #ccc;
      transition: 0.2s;
      border-radius: 50%;
      z-index: 3;
    }
  }
`;

export default function DarkModeSwitch() {
  const [play] = UseSound(swtichSound);

  const [darkMode, setDarkMode] = useState(false);

  // This runs when the page is loaded.
  useEffect(() => {
    if (localStorage.getItem("darkMode") === "true") {
      document.querySelector(".darkModeToggle").checked = true;
      toggleDarkMode(true);
    }
  }, []);

  const handleClick = () => {
    document.querySelector(".darkModeToggle").checked
      ? localStorage.setItem("darkMode", "true")
      : localStorage.setItem("darkMode", "false");
    toggleDarkMode(document.querySelector(".darkModeToggle").checked);
  };

  function toggleDarkMode(darkMode) {
    //could be a tempalte literal to replace dark with light and vice versa on variable entries, if they were all named up that way.
    if (document.querySelector(".darkModeToggle").checked) {
      document
        .querySelector(":root")
        .style.setProperty("--body-background", "hsla(227, 15%, 15%, 1)");
      document
        .querySelector(":root")
        .style.setProperty("--background-color", "hsla(227, 15%, 20%, 1)");
      document
        .querySelector(":root")
        .style.setProperty("--text-color", "hsla(227, 15%, 70%, 1)");
      document
        .querySelector(":root")
        .style.setProperty(
          "--header-gradient",
          "linear-gradient(0deg,var(--brand-blue-900) 0%,hsl(227,22%,25%) 20%,hsl(227,12%,17%) 21%,var(--brand-blue-900) 25%,hsl(227,22%,10%) 100%)"
        );
      document.querySelector("header").style.backgroundColor =
        "var(--brand-blue-light)";
      document
        .querySelector(":root")
        .style.setProperty("--main-headings-light", "#ff6600");
      document
        .querySelector(":root")
        .style.setProperty("--logo-banner", "url(${logoBannerDark})");
      document
        .querySelector(":root")
        .style.setProperty(
          "--ultra-light-background",
          "hsla(227, 15%, 35%, 1)"
        );
    } else {
      document
        .querySelector(":root")
        .style.setProperty("--body-background", "hsla(227, 15%, 90%, 1)");
      document
        .querySelector(":root")
        .style.setProperty("--background-color", "#f4f8fb");
      document
        .querySelector(":root")
        .style.setProperty("--text-color", "#474747");
      document
        .querySelector(":root")
        .style.setProperty(
          "--header-gradient",
          "linear-gradient(0deg, hsl(213, 22%, 84%) 0%, hsl(213, 12%, 54%) 20%, hsl(213, 22%, 84%) 100%)"
        );
      //todo. stars come into view? Or a lamp?
      document.querySelector("header").style.backgroundColor =
        "var(--brand-blue-800)";
      document
        .querySelector(":root")
        .style.setProperty("--main-headings-light", "black");
      document
        .querySelector(":root")
        .style.setProperty("--logo-banner", "url(${logoBannerLight})");
      document
        .querySelector(":root")
        .style.setProperty("--ultra-light-background", "#F4F4F4");
    }
  }
  return (
    <OuterDarkmodeSwitch>
      <DarkmodeLabel>
        <input
          className="darkModeToggle"
          type="checkbox"
          onClick={play}
          onChange={handleClick}
        />
        <span></span>
        <p>☼&nbsp;&nbsp;&nbsp;☾</p>
      </DarkmodeLabel>
    </OuterDarkmodeSwitch>
  );
}
