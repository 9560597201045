import { createGlobalStyle } from "styled-components";
import bg from "../assets/images/designerBackground.svg";

const GlobalStyles = createGlobalStyle`

    @import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,900&family=Assistant:wght@200;700&family=Raleway:wght@100;200&display=swap');

    :root {
        --brand-blue-900: hsla(227, 15%, 15%, 1);
        --brand-blue-800: hsla(227, 15%, 20%, 1);
        --brand-blue-700: hsla(227, 15%, 30%, 1);
        --brand-blue-dark: hsla(227, 15%, 35%, 1);
        --brand-blue-400: hsla(227, 15%, 60%, 1);
        --brand-blue-300: hsla(227, 15%, 70%, 1);
        --brand-blue-200: hsla(227, 15%, 80%, 1);
        --brand-blue-light: hsla(227, 15%, 90%, 1);
        --grey: #f9f9f9;
        --brand-orange: #ff6600;
        --brand-orange-shadow: hsla(26, 50%, 29%,1);
        --brand-orange: hsla(26, 100%, 49%,1);
        --brand-orange-light: hsla(26, 100%, 81%,1);
        --brand-orange-100: hsla(26, 100%, 95%,1);
        --brand-highlight-color: purple;
        --brand-highlight-color-hover: rgb(82, 0, 82);
        --text-color: #474747;
        /*** All of the following are altered by darkmode ***/
        --ultra-light-background: #f4f4f4;
        --background-color: white;
        --body-background: hsla(227, 15%, 90%, 1);
        --nav-link: #f9f9f9;
        --unavailable-text-color: #ddd;
        --default-transition: all 0.1s ease-in-out;
        --default-transition-image: all 3s ease-in-out;
        --default-transition-image-snapback: all 3s ease-in-out;
        --default-border-radius: 7px;
        --main-headings-light: black;
        --main-headings-dark: #ff6600;
        --header-gradient: linear-gradient(0deg, hsl(213, 22%, 84%) 0%, hsl(213, 12%, 54%) 20%, hsl(213, 22%, 84%) 100%);

        /* The below is for darkmode transition */
        --transition-delay: 0.2s;
        --transition-period: 0.3s ease-out;
    }

    df-messenger {
        /* dialogflow styling */
        --df-messenger-button-titlebar-color: hsla(227, 15%, 15%, 1);
        --df-messenger-chat-background-color: #efefef;
        --df-messenger-bot-message: hsla(227, 15%, 20%, 1);
        --df-messenger-font-color: white;
        --df-messenger-send-icon: hsla(227, 15%, 20%, 1);
        --df-messenger-user-message: hsla(227, 15%, 60%, 1);
    }

    #widgetIcon {
      border-radius: none;
    }

    html {
    font-size: 10px;
    background-color: var(--body-background);
    background-image: url(${bg});
    background-size: 1200px;
    background-attachment: fixed;
    color: var(--text-color);
    font-weight: 100;
    margin: 0;
    padding: 0;
    transition: background-color var(--transition-period);
    transition-delay: var(--transition-delay);
  }



  body {
    font-family: 'Raleway', sans-serif;
    font-weight: 100;
    letter-spacing: -1px;
    font-size: 2rem;
    line-height: 2rem;
    margin: 0;
    padding: 0;
  }

  .darkTheme, .darkTheme .div, .darkTheme .cardText{
    background-color: var(--brand-blue-900);
    color: var(--brand-blue-light);
  }

  .darkTheme h2 {
    color: var(--brand-orange);
  }

  fieldset {
    border-color: rgba(0,0,0,0.1);
    border-width: 1px;
  }

  header {
    background-color: var(--brand-blue-light);
    background: var(--header-gradient);
    transition: all var(--transition-period);
    transition-delay: var(--transition-delay);
  }

   h2 {
    letter-spacing: -1px;
    /* padding: 40px 50px 0 50px; */
    margin: 10px auto 0px auto;
    font-family: "Lato", sans-serif;
    font-weight: 700;
    /* font-style: italic; */
    font-size: 1.8em;
    line-height: 0.9em;
    text-transform: uppercase;
    color: var(--main-headings-light);
    z-index: 1;
    transition: color var(--transition-period);
    transition-delay: var(--transition-delay);
  }

  h4 {
    margin-bottom: 0;
  }

  a {
    color: var(--text-color);
    text-decoration: underline #666 1px;
  }

  a:hover {
    color: var(--brand-orange);
  }

  .introParagraph {
    padding: 0em 1em 3em 1em;
  }

  mark, .mark {
    background: var(--brand-blue-light);
    padding: 0 2px 2px 2px;
    margin: 0;
    display: inline-block;
    line-height: 1;
    top: 5rem;
  }

  
.starRating {
  box-shadow: none!important;
}

/* .gatsby-image-wrapper img[src*=base64\\,] {
    image-rendering: -moz-crisp-edges;
    image-rendering: pixelated;
  } */

`;
export default GlobalStyles;
