import { Link } from "gatsby";
import React from "react";
import styled from "styled-components";

const FooterContainer = styled.footer`
  padding: 1rem 0;
  /* position: fixed; */
  bottom: 0;
  width: 100%;
  font-size: 1rem;
  text-align: center;
  background-color: var(--brand-blue-dark);
  color: white;
  z-index: 10;
  border-top: 3px solid white;
  a {
    text-decoration: none;
    color: white;
  }
  p {
    margin: 0px;
  }
`;

export default function Footer() {
  return (
    <FooterContainer>
      <p>&copy; Nicholas Shaw 2010 - {new Date().getFullYear()} </p>
      <p>
        <Link to="/privacy-policy">Privacy policy</Link>
        &nbsp;&nbsp;|&nbsp;&nbsp;
        <Link to="/attribution">Attribution and credit</Link>
      </p>
    </FooterContainer>
  );
}
