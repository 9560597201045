import { Link as GatsbyLink } from "gatsby";
import React from "react";
import styled from "styled-components";
import headerLogo from "../assets/images/headerLogo.png";
import DarkModeSwitch from "./DarkModeSwitch";
import { BiHomeAlt2, BiBookOpen, BiUser, BiEnvelope } from "react-icons/bi";

const TopMenuItems = styled.ul`
  position: sticky;
  margin: 0;
  top: 0rem;
  left: 0rem;
  list-style: none;
  /* display: flex; */
  flex-direction: row;
  /* gap: 2rem; */
  padding: 1rem 0px;
  z-index: 10;
  /* border-radius: 5px; */
  background-color: var(--brand-blue-dark);
  backdrop-filter: blur(20px);
  border-bottom: 5px solid var(--brand-blue-400);
  transform: all 0.2s ease-in-out;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.3);
  &:hover {
    /* background-color: rgba(0, 0, 0, 0.7); */
    transform: all 0.2s ease-in-out;
  }

  .navText {
    display: inline-block;
  }
  a {
    font-weight: 400;
    text-transform: uppercase;
    text-decoration: none;
    padding: 1rem 0rem;
    color: var(--nav-link);
  }
  a:hover {
    color: var(--brand-orange);
    text-decoration: underline 1px;
  }
  li {
    vertical-align: middle;
    display: inline-block;
    list-style: none;
    padding: 0 1rem;
    margin: 0;
  }
  img {
    position: relative;
    height: 26px;
    margin: 0;
  }
  @media (max-width: 650px) {
    .navIcon {
      display: inline-block;
    }
    .navText {
      display: none;
    }
    li {
      padding: 0 1.5rem;
    }
  }
`;

const NavIcon = styled((props) => <GatsbyLink {...props} />)`
  display: none;
  font-size: 3rem;
  @media (max-width: 650px) {
    display: inline-block;
  }
`;

const NavText = styled((props) => <GatsbyLink {...props} />)`
  display: inline-block;
  @media (max-width: 650px) {
    display: none;
  }
`;

export default function TopMenu() {
  return (
    <>
      <TopMenuItems>
        <li>
          <GatsbyLink to="/">
            {" "}
            <img className="headerLogo" src={headerLogo} alt="Nicholas Shaw" />
          </GatsbyLink>
        </li>
        <li>
          <NavText to="/">Home</NavText>
          <NavIcon to="/">
            <BiHomeAlt2 />
          </NavIcon>
        </li>
        <li>
          <NavText to="/portfolio">Portfolio</NavText>
          <NavIcon to="/portfolio">
            <BiBookOpen />
          </NavIcon>
        </li>
        <li>
          <NavText to="/aboutMe">About me</NavText>
          <NavIcon to="/aboutMe">
            <BiUser />
          </NavIcon>
        </li>
        <li>
          <NavText to="/contact">Contact</NavText>
          <NavIcon to="/contact">
            <BiEnvelope />
          </NavIcon>
        </li>
        <li>
          <DarkModeSwitch />
        </li>
      </TopMenuItems>
    </>
  );
}
